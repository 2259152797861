import { Container, Nav, NavbarBrand } from "react-bootstrap";
import { ReactComponent as SoloWorkmark } from "assets/img/solo-wordmark-logo.svg";
import { ReactComponent as TwitterIcon } from "assets/social/Twitter-solo.svg";
import { ReactComponent as LinkedInIcon } from "assets/social/LinkedIn-solo.svg";
import { ReactComponent as YoutubeIcon } from "assets/social/Youtube.svg";
import { ReactComponent as SlackIcon } from "assets/social/Slack_RGB.svg";
import { ReactComponent as GithubIcon } from "assets/social/github.svg";
import styled from "@emotion/styled";
import { colors } from "Styles";

const FooterContentWrapper = styled.div`
  background-color: #f8fafb;
  padding: 4rem 0;

  #menu-legal li {
    display: inline-block;
  }

  .menu {
    margin-top: 12px;
  }

  #menu-legal {
    list-style: none;
  }

  #menu-legal li + li:before {
    content: "|";
    margin: 0 7px 0 3px;
  }

  #menu-legal .copyright {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 575px) {
    padding: 2rem 0;
  }
`;

const FooterNav = styled.div`
  #menu-footer > li a:hover {
    color: ${colors.oceanBlue};
  }

  #menu-footer {
    display: flex;
    list-style: none;
    margin: 0 0 0.5rem;
    padding: 0;
  }

  #menu-footer > li {
    flex: 1;
    font-weight: 700;
    font-size: 1.25rem;
    margin: 0 0 0.5rem;
    page-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid-column;
    margin-bottom: 16px;
  }

  #menu-footer > li a {
    color: #253e58;
    margin-bottom: 5px;
    text-decoration: none;
  }

  #menu-footer > li ul {
    list-style: none;
    padding: 6px 0 0;
  }

  #menu-footer > li ul li {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  #menu-footer > li ul li a {
    color: #45698a;
    font-weight: 300;
  }

  .nav-products {
    order: 1;
  }

  .nav-open-source {
    order: 2;
  }

  .nav-solutions {
    order: 3;
  }

  .nav-company {
    order: 4;
  }

  .nav-resources {
    order: 5;
  }

  @media screen and (max-width: 1199px) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 767px) {
    #menu-footer {
      display: block;
    }
  }
`;

const MiscWrap = styled.div`
  text-align: left;
  color: #8693a5;
  margin-top: 25px;

  a {
    font-weight: 700;
    color: #8693a5;
  }

  #menu-legal {
    padding-left: 0;
  }

  @media screen and (max-width: 575px) {
    border-top: 1px solid #f2f2f2;
    padding-top: 1.5rem;
    text-align: center;
  }
`;

const FooterSocial = styled.div`
  padding: 1rem 0;
  background-color: #fff;

  > .container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 575px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
`;

export const Footer = () => {
  return (
    <footer>
      <FooterContentWrapper>
        <Container>
          <FooterNav>
          <ul id="menu-footer">
            <li>Products <ul>
                <li>
                  <a href="https://solo.io/products/gloo-platform/">Gloo Platform</a>
                </li>
                <li>
                  <a href="https://solo.io/products/gloo-gateway/">Gloo Gateway</a>
                </li>
                <li>
                  <a href="https://solo.io/products/gloo-mesh/">Gloo Mesh</a>
                </li>
                <li>
                  <a href="https://solo.io/products/gloo-network/">Gloo Network</a>
                </li>
                <li>
                  <a href="https://solo.io/products/gloo-fabric/">Gloo Fabric</a>
                </li>
              </ul>
            </li>
            <li>Learn <ul>
                <li>
                  <a href="https://solo.io/customers/">Customers</a>
                </li>
                <li>
                  <a href="https://solo.io/resources/">Resource Library</a>
                </li>
                <li>
                  <a href="https://solo.io/blog/">Blog</a>
                </li>
                <li>
                  <a href="https://academy.solo.io" target="_blank">Solo Academy</a>
                </li>
                <li>
                  <a href="https://docs.solo.io" target="_blank">Docs</a>
                </li>
              </ul>
            </li>
            <li>Topic Series <ul>
                <li>
                  <a href="https://solo.io/topics/">All Topic Series</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/api-gateway/">API Gateway</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/api-management/">API Management</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/aws-api-gateway/">AWS API Gateway</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/cilium/">Cilium</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/kubernetes-api-gateway/">Kubernetes API Gateway</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/linkerd/">Linkerd</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/microservices/">Microservices</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/nginx/">NGINX</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/openshift/">OpenShift</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/rate-limiting/">Rate Limiting</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/service-mesh/">Service Mesh</a>
                </li>
                <li>
                  <a href="https://solo.io/topics/zero-trust/">Zero Trust</a>
                </li>
              </ul>
            </li>
            <li>Company <ul>
                <li>
                  <a href="https://www.solo.io/events/">Upcoming Events</a>
                </li>
                <li>
                  <a href="https://solo.io/company/careers/">Careers</a>
                </li>
                <li>
                  <a href="https://solo.io/company/about-us/">About Us</a>
                </li>
                <li>
                  <a href="https://solo.io/company/news-room/">News Room</a>
                </li>
                <li>
                  <a href="https://www.solo.io/partners/">Partners</a>
                </li>
                <li>
                  <a href="https://soloswag.mybigcommerce.com/" target="_blank">Swag Store</a>
                </li>
                <li>
                  <a href="https://solo.io/company/open-source/">Open Source</a>
                </li>
              </ul>
            </li>
            <li>Contact Solo <ul>
                <li>
                  <a href="https://solo.io/company/contact/">Contact Solo.io</a>
                </li>
                <li>
                  <a href="https://solo.io/company/get-support/">Get Support</a>
                </li>
                <li>
                  <a href="https://solo.io/company/contact-sales/">Contact Sales</a>
                </li>
                <li>
                  <a href="https://solo.io/pricing/">Pricing</a>
                </li>
              </ul>
            </li>
          </ul>
          </FooterNav>
          <MiscWrap>
            <div className="copyright">© 2023 Solo.io. All Rights Reserved.</div>
            <div className="menu">
              <ul id="menu-legal" className="menu">
                <li>
                  <a href="https://legal.solo.io/#privacy-policy" target="_self">
                    Privacy Policy
                  </a>
                </li>{" "}
                <li>
                  <a href="https://solo.io/security/" target="_self">
                    Security
                  </a>
                </li>{" "}
                <li>
                  <a href="https://legal.solo.io/#subscription-terms" target="_self">
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>
          </MiscWrap>
        </Container>
      </FooterContentWrapper>

      <FooterSocial>
        <Container>
          <NavbarBrand href="/">
            <SoloWorkmark width="123" height="46" className="logo" />
          </NavbarBrand>
          <Nav className="social-media-nav">
            <Nav.Item>
              <Nav.Link href="https://twitter.com/soloio_inc" target="_blank" aria-label="Twitter">
                <TwitterIcon />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.linkedin.com/company/solo.io" target="_blank" aria-label="linkedin">
                <LinkedInIcon />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.youtube.com/soloio_inc" target="_blank" aria-label="youtube">
                <YoutubeIcon />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://slack.solo.io/" target="_blank" aria-label="slack">
                <SlackIcon />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://github.com/solo-io" target="_blank" aria-label="github">
                <GithubIcon />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </FooterSocial>
    </footer>
  );
};
