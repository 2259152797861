import { Container, Nav, Row } from "react-bootstrap";

export const LearnDropdownContent = () => {
  return (
    <Container>
      <Row className="nav-row">
        <div className="col-md-5 white-col nav-col">
          <div className="left-sub-nav d-none d-md-block">
            <h4>Get Started</h4>{" "}
            <p>
              Get started managing application networking, building an API gateway, creating GraphQL endpoints or
              collaborating across your organization.
            </p>
            {/* Navigation Links Start */}
            <a href="https://solo.io/products/gloo-mesh/" className="simple-link" target="_self">
              GLOO MESH
            </a>{" "}
            <a href="https://solo.io/products/gloo-edge/" className="simple-link" target="_self">
              {" "}
              GLOO EDGE
            </a>{" "}
            <a href="https://solo.io/products/gloo-portal/" className="simple-link" target="_self">
              {" "}
              GLOO PORTAL
            </a>{" "}
            {/* Navigation Links End */}
            {/* Navigation Buttons Start */}
            {/* Navigation Buttons End */}
            {/* Navigation Buttons Popup Start */}
            {/* Navigation Buttons Popup End */}
          </div>
        </div>
        {/* /.col-md-4  */}
        <div className="col-md-4 grey-col nav-col">
          <div className="center-sub-nav">
            <ul className="nav flex-column">
              <Nav.Item>
                <Nav.Link href="https://solo.io/resources/" target="_self">
                  Resource Library
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://solo.io/blog/" target="_self">
                  Blog
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://academy.solo.io/learn" target="_self">
                  Solo Academy
                </Nav.Link>
              </Nav.Item>
            </ul>
          </div>
        </div>

        <div className="col-md-3 white-col nav-col">
          <div className="right-sub-nav">
            {/* Navigation start */}
            <ul className="nav flex-column">
              <Nav.Item>
                <h5>Events</h5>
                <ul className="nav flex-column">
                  <Nav.Item>
                    <Nav.Link href="https://www.solo.io/events/" target="_self">
                      Upcoming
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.solo.io/resources/webinar/" target="_self">
                      On-Demand
                    </Nav.Link>
                  </Nav.Item>
                </ul>
              </Nav.Item>
            </ul>
            {/* Navigation End */}

            {/* Content and  Navigation Start */}
            {/* Content and  Navigation End */}

            {/* Text, Image and Content Start */}

            {/* Text, Image and Content End */}
          </div>
        </div>
      </Row>
    </Container>
  );
};
